import React from 'react'
import './Footer.css';
const Footer = () => {
  return (
    <>
    <div className='footer'>
     <div className='container'>
      <div className='row'>
<div className='ssd col-lg-6'>
<ul className='ulfooter'>
  <li><a href='/privecy'>Privacy Policy</a> </li>
  <li><a  href='/terms'>Terms & Conditions</a> </li>
  <li><a  href='https://merchant.razorpay.com/policy/KJRC94HTg35h4r/refund' >Cancellation & Refund Policy</a> </li>
  <li><a  href='/payment'>Payment Method</a> </li>
</ul>
</div>
<div className=' ssdd col-lg-6'>
<ul className='ulfooter'>
  <li><a href='https://www.facebook.com/cleannation00'>Fecebook</a></li>
  <li><a  href='https://www.instagram.com/cleannationin/'>Instagram</a> </li>
  <li><a  href='https://www.youtube.com/channel/UCu4dWliy_QEYzh5O6Jw6EWyQ' >Youtube</a> </li>
  <li><a  href='/faqs'>FAQs</a> </li>
  <li><a  href='/backlink'>Related-Websites</a></li>

</ul>
</div>
      </div>
     </div>
    </div>
      <p className='footerr'>@2020_CLEANNATION.IN_ALL OVER INDIA.</p>
    </>
  )
}

export default Footer
